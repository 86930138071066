import {
  AgentField,
  ErrorStatus,
  MarketingMaterialTableItem,
  MktMatFieldMap,
  OptionsField,
} from "shared/types/marketingMaterials";
import {
  AgentFeedInfo,
  Layer,
  SmartVariable,
  Template,
} from "shared/types/salesEnablement";
import { getPageLayers } from "utils/helpers.salesEnablement";
import { validateRequired as ValidateAgentRequired } from "screens/adLibrary/marketingMaterialDrawer/marketingMaterialForm/AgentList";
import { validateRequired as ValidateAccountRequired } from "screens/adLibrary/marketingMaterialDrawer/marketingMaterialForm/AccountLayerInput";
import { validateRequired as ValidateProductRequired } from "screens/adLibrary/marketingMaterialDrawer/marketingMaterialForm/ProductLayer";
import { validateRequired as ValidateOptionRequired } from "screens/adLibrary/marketingMaterialDrawer/marketingMaterialForm/OptionGroupLayerInput";
import { validateRequired as ValidateImageRequired } from "screens/adLibrary/marketingMaterialDrawer/marketingMaterialForm/ImageLayer";
import { Rule } from "antd/lib/form";
import { noop } from "lodash";
import {
  createAgentSchema,
  isLicenseField,
  isLicenseRequiredStandalone,
} from "screens/adLibrary/marketingMaterialDrawer/hooks/agentManagement";
import { StateKey } from "shared/constants/states";

type LayerType = "text" | "image" | SmartVariable | "non-editable";

export const getMarketingMaterialValidationErrors = ({
  material,
  template,
  getAgentInfo,
  materialErrorStatus,
}: {
  material: MarketingMaterialTableItem;
  template: Template;
  getAgentInfo: (email: string) => Promise<AgentFeedInfo>;
  materialErrorStatus?: ErrorStatus;
}) => {
  if (materialErrorStatus) {
    return Promise.resolve({
      errors: [materialErrorStatus],
      isValid: false,
    });
  }

  const fieldValues = material.fields ?? {};

  const templateFile = template?.files[material.language];

  if (!templateFile) {
    return Promise.resolve({
      errors: [
        {
          message: "Template file not found",
        },
      ],
      isValid: false,
    });
  }

  const fieldsMap = Object.fromEntries(
    getPageLayers(templateFile)?.map<
      [string, { type: LayerType; name: string }]
    >(layer => [layer.id, { type: getLayerType(layer), name: layer.name }]) ??
      [],
  );

  return getErrors({
    fieldsMap,
    fieldValues,
    locations: material.locations,
    getAgentInfo,
  });
};

const getErrors = async ({
  fieldsMap,
  fieldValues,
  locations,
  getAgentInfo,
}: {
  fieldsMap: Record<string, { type: string; name: string }>;
  fieldValues: MktMatFieldMap;
  locations: StateKey[];
  getAgentInfo: (email: string) => Promise<AgentFeedInfo>;
  disclosureFieldId?: string;
}) => {
  const errorValidations = Object.entries(fieldsMap).flatMap(
    async ([fieldId, { type: fieldType, name: layerName }]) => {
      switch (fieldType) {
        case "text":
          return validateText(
            fieldValues[fieldId] as string,
            locations,
            layerName,
          );
        case "image":
          return validateRule(fieldValues[fieldId], ValidateImageRequired);
        case "account":
          return validateRule(fieldValues[fieldId], ValidateAccountRequired);
        case "options":
          return validateOptions(
            fieldValues[fieldId] as OptionsField,
            locations,
          );
        case "product":
          return validateRule(fieldValues[fieldId], ValidateProductRequired);
        case "agent":
          return validateAgent(
            fieldValues[fieldId] as AgentField,
            locations,
            getAgentInfo,
          );
        default:
          return {
            isValid: true,
            message: "",
          };
      }
    },
  );

  const settleResults = await Promise.allSettled(errorValidations);
  const errors = settleResults
    .filter(isRejected)
    .map(result => result.reason as string | undefined);

  return {
    errors,
    isValid: errors.length === 0,
  };
};

const validateText = (
  fieldValue: string,
  locations: StateKey[],
  layerName: string,
) => {
  if (
    isLicenseField(layerName) &&
    isLicenseRequiredStandalone(locations) &&
    !fieldValue
  ) {
    return Promise.reject({
      message: "License is required in AR and CA.",
      isValid: false,
    });
  }
  if (
    isLicenseField(layerName) &&
    !isLicenseRequiredStandalone(locations) &&
    !fieldValue
  ) {
    return Promise.resolve({
      isValid: true,
      message: "",
    });
  }
  if (!fieldValue)
    return Promise.reject({
      message: `${layerName} is required`,
      isValid: false,
    });
  return Promise.resolve({
    isValid: true,
    message: "",
  });
};

const validateOptions = (optionsField: OptionsField, locations: StateKey[]) => {
  const fieldKeys = Object.keys(optionsField.childOptions);
  fieldKeys.forEach(fieldName => {
    if (
      isLicenseField(fieldName) &&
      isLicenseRequiredStandalone(locations) &&
      !optionsField.childOptions[fieldName]
    )
      return Promise.reject({
        message: "License is required in AR and CA.",
        isValid: false,
      });
  });

  return validateRule(optionsField, ValidateOptionRequired);
};

const validateAgent = async (
  fieldValue: AgentField,
  locations: StateKey[],
  getAgentInfo: (email: string) => Promise<AgentFeedInfo>,
) => {
  const agentSchema = createAgentSchema(
    fieldValue.templateFieldNames,
    locations,
    getAgentInfo,
  );
  try {
    await Promise.all(
      fieldValue.agentsData.map(
        async agent => await agentSchema.parseAsync(agent),
      ),
    );
  } catch (e) {
    return Promise.reject({
      message: "Invalid agent data",
      isValid: false,
    });
  }
  return validateRule(fieldValue, ValidateAgentRequired);
};

const isRejected = <T>(
  input: PromiseSettledResult<T>,
): input is PromiseRejectedResult => input.status === "rejected";

const validateRule = async <T>(
  fieldValue: T,
  rules: Rule[],
  fieldName?: string,
) => {
  const rule = getValidationFromRule(rules);
  if (!fieldValue)
    return Promise.reject({
      message: `${fieldName ?? fieldValue} is required`,
      isValid: false,
    });

  try {
    await rule.validator?.(fieldValue);
  } catch (e) {
    return Promise.reject({
      message: rule.message,
      isValid: false,
    });
  }

  return Promise.resolve({
    message: "",
    isValid: true,
  });
};

const getLayerType = (layer: Layer): LayerType => {
  if (
    layer.smartVariable &&
    ["expirationDate", "formNumber", "disclosure", "conditional"].includes(
      layer.smartVariable,
    )
  ) {
    return "non-editable";
  }

  const type = layer.type;

  if (type === "text" || type === "image") {
    return type;
  }

  if ("options" in layer) {
    return "options";
  }

  return layer.smartVariable ?? "non-editable";
};

const getValidationFromRule = (rules: Rule[]) => {
  const rule = rules?.[0];
  if (!rule || !("validator" in rule))
    return { validator: () => true, message: "" };

  return {
    validator: (value: any) => rule.validator?.({}, value, noop),
    message: rule.message as string,
  };
};
