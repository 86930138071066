import { Rule } from "antd/lib/form";
import { mapValues } from "lodash";
import { useMemo } from "react";
import { formatPhoneNumber } from "shared/components/InputPhone";
import { states, type StateKey } from "shared/constants/states";
import { useAgentsFeedInfoByEmail } from "shared/hooks/salesEnablement/useAgentFeedData";
import { filterLicensedStates } from "shared/hooks/salesEnablement/useAgentLicensedStates";
import { useGetAgentProfiles } from "shared/hooks/salesEnablement/useAgentProfiles";
import { useUser } from "shared/hooks/useUser";
import { AgentField, AgentFieldData } from "shared/types/marketingMaterials";
import { AgentFeedInfo, AgentGroupLayer } from "shared/types/salesEnablement";
import { joinArray } from "utils/helpers.array";
import { z } from "zod";

export interface AgentProfile {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
}

export interface AgentProfileWithLicensedStates extends AgentProfile {
  licensedStates: StateKey[] | undefined;
}

export const useUserAsAgentProfile = () => {
  const user = useUser();

  return useMemo<AgentProfile>(() => {
    return {
      id: user.sub,
      email: user.email,
      firstName: user.user_metadata?.firstName || "",
      lastName: user.user_metadata?.lastName || "",
      licenseNumber: "",
      phoneNumber: formatPhoneNumber(user.user_metadata?.phoneNumber),
      createdAt: "",
      updatedAt: "",
    };
  }, [user]);
};

/**
 * @returns List of all agents including the current user
 */
export const useAllAgents = () => {
  const userAsAgentProfile = useUserAsAgentProfile();
  const { data: savedAgents = [], isLoading } = useGetAgentProfiles();
  const agentFeedInfo = useAgentsFeedInfoByEmail(
    savedAgents.map(agent => agent.email),
  );

  const agents = useMemo(() => {
    return [userAsAgentProfile]
      .concat(savedAgents)
      .map<AgentProfileWithLicensedStates>(agent => {
        const agentFeed =
          agentFeedInfo.agentProfileByEmail[agent.email.toLowerCase()];
        const licensedStates = filterLicensedStates(agentFeed?.licensedStates);
        return {
          ...agent,
          licensedStates,
        };
      });
  }, [userAsAgentProfile, savedAgents, agentFeedInfo]);

  return { agents, isLoading: isLoading || agentFeedInfo.isLoading };
};

export const getAgentFieldValue = (agent: AgentProfile, fieldName: string) => {
  const cleanField = fieldName.toLowerCase().replace(/\s/g, "");

  if (isEmailField(fieldName)) return agent.email;
  if (isPhoneField(fieldName)) return formatPhoneNumber(agent.phoneNumber);

  switch (cleanField) {
    case "name":
    case "fullname":
      return joinArray([agent.firstName, agent.lastName], " ");
    case "firstname":
      return agent.firstName;
    case "lastname":
      return agent.lastName;
    default:
      return "";
  }
};

export const isEmailField = (fieldName: string) => {
  return fieldName.toLowerCase().includes("email");
};

export function isPhoneField(fieldName: string) {
  return (
    fieldName.toLowerCase().includes("phone") ||
    fieldName.toLowerCase() === "number"
  );
}

export function isNameField(fieldName: string) {
  return fieldName.toLowerCase().includes("name");
}

export function isLicenseField(fieldName: string) {
  return fieldName.toLowerCase().includes("license");
}

export function getLicenseValidationRule(): Rule[] {
  return [
    ({ getFieldValue }) => ({
      validator: (_, value) => {
        if (isLicenseRequired(getFieldValue) && !value) {
          return Promise.reject(new Error("License is required in AR and CA."));
        }
        return Promise.resolve();
      },
    }),
  ];
}

export function isLicenseRequired(
  getFieldValue: (fieldName: string[]) => string[],
) {
  const locations: string[] = getFieldValue(["locations"]) ?? [];

  return isLicenseRequiredStandalone(locations);
}

export function isLicenseRequiredStandalone(locations: string[]) {
  return locations.some(location =>
    ([states.AR.id, states.CA.id] as string[]).includes(location),
  );
}

export function getEmptyAgentFieldData(fieldNames: string[]): AgentFieldData {
  const entries = Object.fromEntries(fieldNames.map(name => [name, ""]));
  return {
    ...entries,
    refAgentId: undefined,
  };
}

export function getAgentTemplateFieldNames(layer: AgentGroupLayer) {
  return layer.subLayers.map(subLayer => subLayer.name);
}

export function getAgentDataFromAgent(
  agent: AgentProfile,
  templateFieldNames: string[],
): AgentFieldData {
  const emptyAgentData = getEmptyAgentFieldData(templateFieldNames);

  const agentFieldData = mapValues(emptyAgentData, (_, key) =>
    getAgentFieldValue(agent, key),
  );

  return {
    ...agentFieldData,
    refAgentId: agent.id,
  };
}

export function getAgentValues(
  layer: AgentGroupLayer,
  userAsAgent: AgentProfile,
) {
  const templateFieldNames = getAgentTemplateFieldNames(layer);
  const firstAgentData = getAgentDataFromAgent(userAsAgent, templateFieldNames);

  return {
    id: layer.id,
    type: "agent",
    name: layer.name,
    agentsData: [firstAgentData],
    templateFieldNames,
  } satisfies AgentField;
}

const allSpecialStates = ["CA", "AR"];

export const isSpecialState = (state: string | undefined) => {
  return state && allSpecialStates.includes(state);
};

export const specialStateLicensePrefix = (state: string) => {
  switch (state) {
    case "CA":
      return "CA License No. ";
    case "AR":
      return "AR Insurance Producer License No. ";
    default:
      return "";
  }
};

export function createAgentSchema(
  templateFieldNames: string[],
  locations: StateKey[],
  getAgentInfo: (agentEmail: string) => Promise<AgentFeedInfo>,
) {
  return templateFieldNames.reduce((acc, fieldName) => {
    if (isPhoneField(fieldName)) {
      return acc.merge(
        z.object({
          [fieldName]: z.string().regex(/^\d{3}\.\d{3}\.\d{4}$/),
        }),
      );
    }
    if (isNameField(fieldName)) {
      return acc.merge(
        z.object({
          [fieldName]: z.string(),
        }),
      );
    }
    if (isLicenseField(fieldName) && isLicenseRequiredStandalone(locations)) {
      return acc.merge(
        z
          .object({
            [fieldName]: z
              .string()
              .max(isLicenseRequiredStandalone(locations) ? 16 : 80),
          })
          .required(),
      );
    }

    if (isEmailField(fieldName)) {
      return acc.merge(
        z.object({
          [fieldName]: z
            .string()
            .email()
            .refine(async value => {
              const agentInfo = await getAgentInfo(value);
              if (
                locations &&
                isMissingLicense(
                  filterLicensedStates(agentInfo?.licensedStates),
                  locations,
                )
              ) {
                return false;
              }
              return true;
            }, "Email not found in system"),
        }),
      );
    }

    return acc;
  }, z.object({}));
}

export const isMissingLicense = (
  agentLicensedStates?: StateKey[],
  selectedLocations?: StateKey[],
) =>
  !!selectedLocations?.some(
    location => !agentLicensedStates?.includes(location),
  );
