import {
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
} from "antd";
import {
  PrintOptionCopyType,
  PrintOptionsCopy,
} from "shared/types/printOptions";
import { MinMaxInput } from "./MinMaxInput";
import { PrintModeRadioGroup } from "./printOptionsFields/PrintModeRadioGroup";

export const PrintOptionsFields = ({ form }: { form: FormInstance }) => {
  return (
    <>
      <Row justify="space-between" gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input the name!" }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Weight per Copy"
            name="weight"
            rules={[
              {
                required: true,
                message: "Please input the weight per copy!",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} min={0.01} step={0.01} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Estimated Production Time"
            name="productionTime"
            rules={[
              {
                required: true,
                message: "Please input the estimated production time!",
              },
            ]}
          >
            <MinMaxInput fieldName={["productionTime"]} allowEqualBounds />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Reference No."
            name="referenceNumber"
            rules={[
              {
                required: true,
                message: "Please input the reference number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Copy Selection" name="copySelection">
            <Radio.Group
              onChange={(e: RadioChangeEvent) => {
                const copySelection = e.target.value;
                const quantity =
                  copySelection === PrintOptionCopyType.RANGE
                    ? {
                        min: 1,
                      }
                    : 0;
                const costPerCopy: PrintOptionsCopy<PrintOptionCopyType>[] =
                  form.getFieldValue("costPerCopy");
                form.setFieldsValue({
                  copySelection,
                  costPerCopy: costPerCopy.map(cost => ({
                    ...cost,
                    quantity,
                  })),
                });
              }}
            >
              <Radio value={PrintOptionCopyType.RANGE}>Quantity Range</Radio>
              <Radio value={PrintOptionCopyType.FIXED}>Fixed Amount</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Print Mode" name="printMode">
            <PrintModeRadioGroup />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
